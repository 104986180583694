import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 0 10vw;
  text-align: justify;
`;
const RedTitle = styled.h3`
  /* text-align: center; */
  font-size: 1.2rem;
  color: var(--red-color);
  margin-top: 15px;
`;
export default function Terms() {
  return (
    <Container>
      <h4>REPEDDLE TERMS OF USE</h4>

      <RedTitle> 1. Introduction</RedTitle>
      <p>
        Welcome! You are entering into this agreement with Tyslane Limited,
        Trading as “Repeddle”. These Terms of use (“Terms”) regulates the use
        and access to the entire Service provided on the App and Website, order
        and supply through this App and Website hereto known as (“Repeddle
        Platform” The Service) under which you may use the App (IOS; Android) or
        Website www.repeddle.com and www.repeddle.co.za BY using this Platforms,
        you expressly agree to be bound by the Terms of use, Privacy Policies,
        Return Policies, Guide, Instructions and every other legal binding
        contain throughout this website “The service”. In the case you have any
        objection against any of this Terms, you may not use or/may discontinue
        in the use of this service.
      </p>
      <RedTitle>1.1 Overview</RedTitle>
      <p>
        Tyslane is a Company incorporated and registered under CAC as “Tyslane
        Limited” with registration number: RC1381794, trading as Repeddle on the
        App and Website www.repeddle.com and www.repeddle.co.za. Throughout the
        Platform, the terms “we”, “us” and “our” refer to Repeddle and the terms
        “items”, “goods” “products” “service” refer to the order listed, placed,
        purchase or service from this website. Repeddle offers this App and
        Website, including all information, tools and services available from
        this website to you, refer to as “the user” an individual or an entity
        you represent, conditioned and legally bound upon your acceptance of all
        terms, conditions, policies and notices stated herein.
      </p>
      <p>
        By visiting the website and/ or purchasing something from us, you engage
        in our “Service” and agree to be bound by the following terms (“Terms of
        Use”, “Terms”), including those additional terms and conditions and
        policies referenced herein and/or available by hyperlink. These Terms of
        Use apply to all users of The Service, including without limitation
        users who are Browsers, Influencers, Buyers, Sellers, Affiliates, Third
        parties and/or Contributors of content.
      </p>
      <p>
        Please read these Terms of Use carefully before accessing or using our
        website. By accessing or using any part of the service, App and Website,
        you agree to be bound by these Terms of Use. If you do not agree to all
        and any of the terms and conditions of this agreement, then you may not
        access the website or use any services. If these Terms of Use are
        considered an offer, acceptance is expressly limited to these Terms of
        Use.
      </p>
      <p>
        Any new features or tools which are added to the current store shall
        also be subject to these Terms of Use. You can review the most current
        version of these Terms of use at any time on this page. We reserve the
        right to update, change or replace any part of these Terms of Use by
        posting updates and/or changes to our App and Website without
        notification. It is your responsibility to check this page periodically
        for changes. Your continued use of or access to the App and Website “The
        Service” following the posting of any changes constitutes acceptance of
        those changes.
      </p>
      <RedTitle>2. Service: Online Store Terms</RedTitle>
      <p>
        This Agreement applies to all Repeddle services, including the Repeddle
        mobile app (made accessible on all and any formats or devices), and the
        websites accessible at www.repeddle.com and www.repeddle.co.za
        (collectively, the “Service(s)”). The Service provides an online social
        marketplace where Users can list and sell product (“products”). Users
        who purchase products are called “Buyers”, and Users who sell products
        are called “Sellers”. Users may be both Buyers and Sellers on the
        Service. In using the Service Buyers and Sellers enter into a contract
        for the sale and purchase of products directly between themselves, and
        Repeddle is not a party to such sale or purchase.
      </p>
      <p>
        There are risks that you assume when dealing with others, and these
        risks are borne by You alone, and not Repeddle. You are solely
        responsible for your evaluation of, and decision to use, the Service,
        Buy, Sell, or transact any business or communications on the Service,
        and will be liable for all of Your actions on the Service.
      </p>
      <p>
        By agreeing to these Terms of Use and use of this service, you represent
        that you are at least the legal age “16” (sixteen), or the age of
        majority in your country, state or province of residence. If you are a
        minor or not legally obligated to undertake legal agreement, you may
        then use this service with only the overseeing of your parent or legal
        guardian. With this consent, such a person agrees to be liable and
        responsible for you, then you and your overseer is bound by this Terms
        and Conditions throughout the Repeddle Platform.
      </p>
      <p>
        You shall not use our platform and service for any illegal or
        unauthorized purpose nor may you, in the use of the Service, violate any
        laws of the country or laws in your jurisdiction (including but not
        limited to copyright laws). In line with the terms of copyright laws and
        regulations, the content and information herein (includes but not
        limited to; Images, videos, materials, data, trade secret, logos,
        software, icons, text, sound clips, graphics, trade names, lay-outs,
        advertisements, trademarks, designs, brands and intellectual properties)
        contain on our App and Website are properties of Repeddle’s brand or its
        third party and therefor protected by copyright law including but not
        limited to trade mark law. No interest of acquisition/right to the
        content of these Sevice or its third party, is granted without
        compliance (permission). You must not transmit to these service, “The
        App and Website” www.repeddle.com and www.repeddle.co.za any worms or
        viruses or any code of a destructive nature, nor are you permitted to
        automatically or manually modify, copy, use, monitor, distribute any
        information on this platform (including but not limited to direct use or
        search engine technology) without prior written permission from
        Repeddle. A breach or violation of any of these Terms of Use will result
        to an immediate termination and or legal actions.
      </p>
      <RedTitle>2.1 Registration</RedTitle>
      <p>
        For a user to make purchase or for a seller to sell on Repeddle’s
        Platform, you may use our platform as a guest user or register for an
        account, known as (User Account) as it applies respectively. Guest users
        only have access to some functionality of the App and Website,
        registered users have access to all functionalities available to all
        users. To create account, you will need to register with your credential
        “Name, Email, Phone Number” or either a G-mail or Facebook. You may
        follow the direction on our website on how to create account, place
        order or make changes to already placed order before submission. As a
        seller, you may carefully follow the step-by-step guidelines on our
        (Seller’s Guideline) to create and personalize your store. The user is
        solely responsible for all activities that take place on the registered
        profile or signed in account. Repeddle is not liable for any access into
        your profile, ensure not to disclose your login details to any party
        whatsoever. We advise you use strong passwords that contains (Capital
        letter, special characters and numbers) when creating a password, and
        should you suspect any unauthorized access into your profile, you are
        advised to immediately report this to Repeddle support team by writing
        at support@repeddle.com or support@repeddle.co.za respectively. While
        Repeddle allow users to shop as guest users, any user willing to sell on
        Repeddle’s platform is required to register and have an account.
        Registering and owning user’s account on Repeddle App and Website is
        absolutely free and have no time bound. For sellers, Repeddle will only
        charge a small percentage for successful sale as set out in
        fees/commission section below. The registration process for the Services
        must be initiated directly by the user on the App or Website. Repeddle
        is entitled to decide in its sole discretion, whether or not to allow a
        potential seller to register for its services. Registration is complete
        when the user log-in to the Portal for the first time.
      </p>
      <p>
        User must not in any way transfer account or profile created by you
        ‘User’ to any party. Each user must have their own exclusive account and
        store profile with its own unique username, email, information and
        storefront of sell (S.O.S). For any reason, should there be need for you
        to transfer your account, you must do so with a prior written consent of
        Repeddle with a permissible reason why you need your account
        transferred, and shall only proceed to do so with Repeddle’s support
        written approval. You agree that you are wholly accountable for activity
        that takes place in your account and in the event the above mention is
        not adhered to, will be deem bridge of contract and may lead to
        temporary account suspension, termination of use of the Services, and or
        deactivation.
      </p>
      <p>
        You agree that; (i) A successful registered user account is capable of
        fulfilling its obligation and rendering the services herein on Repeddle
        App and Website. (ii) A suspended Person wishing to register to be a
        user must make an application to Repeddle. The decision of Repeddle
        official representative in this regard is final and no further
        correspondence shall be entered into with unsuccessful applicants. (iii)
        If the User is a juristic person, any natural person who logs into the
        Account for the first time warrants that he/she has the authority to
        bind the user to this Agreement. As part of the application, in order to
        comply with the Electronic Communications and Transactions Act 2002
        (“ECTA”), you must provide us with your legal name, if you are a
        juristic person (or your real name, if you are a natural person), your
        trading name, registration number (or identity number), main business,
        physical address for legal purposes, office bearers official phone
        number and e-mail address. You must also provide all other documentation
        and information reasonably requested by Repeddle for vetting purposes.
        You hereby authorize us to verify all information that you provide to
        us, and you undertake by entering into this Service, you have provided
        confirmation to such authorization for the verification to be carried
        out by Repeddle (where the need arises).{" "}
      </p>
      <p>
        By registering for or using the Services, you authorize Repeddle to
        perform the Services as set out herein which may include and not limited
        to; Repeddle acting as a third party ‘your agent’ who market and provide
        you the service, use all information including user’s name, Product
        name, Visuals, Logos, Banners, Listing, Trademarks provided on our App
        and website or your social media handles. That you expressly grant us
        intellectual property rights, and or outstanding indications by various
        method and formats for marketing and sells purposes which users may also
        have access to.
      </p>
      <p>
        As a registered user, you use our App and Website, the User
        Account(Profile) and the Services at your own risk. You are solely
        responsible for maintaining the security of your login credentials
        (including your password) to your user account on the Profile. You shall
        be responsible for, and be bound by, any activity on your Repeddle’s
        Account (whether authorized by you or not) which is done using your
        login credentials.{" "}
      </p>
      <p>
        The trading name of your Repeddle’s Account (“User Name”): (i) Must
        accurately reflect the identity of the User; (ii) Must not infringe any
        trademark or intellectual property rights of any third parties,
        guarantees that information provided meets all legal requirements and
        for consumer protection; (iii) Cannot be reflected as a domain name
        (which, by way of an example, ends with “.com”, “.co.za” or “.org”
        Etc.); and/or (iv) Cannot be similar to any trademark owned by Repeddle.
        Repeddle may, in its sole discretion, terminate or suspend any Service,
        or this Agreement as a whole, immediately with or without written notice
        to the User for any non- compliance with this clause. (v) In the event
        user infringes any third party right in any form whatsoever, Users
        agrees to completely indemnify Repeddle against any claim occurring from
        such infringement.
      </p>
      <p>
        Where you register multiple Accounts on Repeddle (whether or not under
        different trading names) for the same user (whether a juristic person or
        natural person), such accounts shall be treated as one Repeddle Account
        for the purposes of this Agreement.
      </p>
      <p>
        Repeddle in its full capacity will ensure its website and services
        remain in great functionality. However, we do not guarantee that the
        platform will be free from all malfunctions should incase any arises.
      </p>
      <RedTitle>3. Third Party Conditions</RedTitle>
      <p>
        We will do everything in our capacity to deliver satisfactory services
        we offer on our App and Website, however, we reserve the right to refuse
        service to anyone for any reason or no reason at any time. Repeddle may
        provide You, or provide Your Content to, certain third party services or
        third party service providers (collectively, &quot;Third Party
        Service(s)&quot;) which may also provide You links to sites, email and
        telephone correspondence and other offers outside of Repeddle, such
        Third Party Services are provided &quot;AS IS&quot; or “AS AVAILABLE”
        without indemnification, support, or warranty of any kind, and this
        Agreement does not apply to Your use of, any such provided Third Party
        Services. You are responsible for evaluating whether You want to access
        or use such Third Party Services, and, in certain circumstances where
        required or applicable, may opt-out from such Third Party Services
        available outside of Repedddle, or may choose to not utilize such Third
        Party Services at any time. We reserve the right to suspend Third Party
        Services at any time.
      </p>
      <p>
        You should review any applicable terms and/or privacy policies of a
        Third Party Service before using it or sharing any information with it,
        because You may give the operator permission to use Your information
        outside of what You have agreed to herein. Repeddle is not responsible
        for, nor endorses any features, content, advertising, products or other
        materials on or available from Third Party Services. You agree not to
        reproduce, duplicate, copy, sell, resell or exploit any portion of the
        Service, use of the Service, or access to the Service or any contact on
        the App and Website through which the service is provided, without
        express written permission by Repeddle.
      </p>
      <p>
        You understand that your content (not including Bank and credit/debit
        card information), may be transferred unencrypted and involve (a)
        transmissions over various networks; and (b) changes to conform and
        adapt to technical requirements of connecting networks or devices.
        Credit/debit card information are always encrypted during transfer over
        networks. Repeddle will not be liable in any way for (1) any article or
        content on the Service posted by third parties, other users, or at the
        direction of users (all, “Third Party Materials”), or (2) Any errors,
        omissions, loss, or damage of any kind as a result of such Third Party
        Materials on the Service; and you acknowledge that Repeddle does not,
        and is under no obligation, to review, screen, or inspect any Third
        Party Materials on the Service, however, Repeddle reserves the right to
        do so, and remove Third Party Materials at Our sole discretion. You
        agree that you bear all risk associated with the use, reliance,
        condition, accuracy, competence, completeness, or usefulness of Third
        Party Materials.
      </p>
      <RedTitle>3.1 Reiteration Third-Party Links</RedTitle>
      <p>
        Certain content, products and services available via our Service may
        include materials from third-parties. Repeddle is not responsible for
        the privacy policy, terms or policies of those third party websites,
        software or applications, as it is outside our jurisdiction including
        those of advertisers and “cookies” used by those third party sites.
        Third-party links on this site may direct you to third-party websites
        that are not affiliated with Repeddle, your use of such links are solely
        at your own risk.
      </p>
      <p>
        We shall not be held responsible for any direct or indirect loss, claims
        or expense that may arise thereof. Furthermore, we are not responsible
        for examining or evaluating the content or accuracy of those website,
        and we do not warrant and will not have any liability or responsibility
        for any third-party materials or websites, or for any other materials,
        products, or services of third-parties.
      </p>
      <p>
        We are not liable for any harm or damages related to the purchase or use
        of goods, services, resources, content, or any other transactions made
        in connection with any third-party websites. Please review carefully the
        third-party's policies and practices and make sure you understand them
        before you engage in any transaction. Complaints, claims, concerns, or
        questions regarding third-party products should be directed to the
        third-party. The headings used in this agreement are included for
        convenience only and will not limit or otherwise affect these Terms.
      </p>
      <RedTitle>
        4. Accuracy, Completeness, Timeliness of Information and Contents
      </RedTitle>
      <p>
        We will not be held responsible if information and content made
        available on this site is not accurate, complete or current. As Users of
        Our Services, all information, including items, messages, offers,
        purchases, sales, etc. that You post, transmit, or submit through Our
        Services (“Content”) is intended to be shared with other Users. By
        submitting any Content to us, you hereby expressly represent and warrant
        that You own all rights to the Content or, alternatively, that You have
        the right to give us the license described below, including the correct
        and legal permissions and consents for any personally identifiable
        information you may provide to, or upload by way of, Repeddle
        Service(s).
      </p>
      <p>
        By posting or otherwise transmitting any User Content, you hereby grant
        and will grant Repeddle and its affiliated companies a nonexclusive,
        worldwide, royalty free, fully paid up, transferable, sub-licensable,
        perpetual, irrevocable license to copy, display, transmit, perform,
        distribute, store, modify, make derivative works of and otherwise use in
        any manner your User Content in connection with the operation of the
        Service or any other products or services of Repeddle, or the promotion,
        advertising or marketing thereof, in any form, medium or technology now
        known or later developed (including without limitation publishing your
        User Content on the Internet or on Third Party Services such as
        Istagram, TikTok, Facebook, sharing it with blogs, etc., and allowing
        other users to share listings that include your User Content).
        Furthermore, you warrant and represent that the Content does not
        infringe on the intellectual property rights, privacy rights, publicity
        rights, or other legal rights of any third party.
      </p>
      <p>
        The material on this site is provided for general information only and
        should not be relied upon or used as the sole basis for making decisions
        without consulting primary, more accurate, more complete or more timely
        sources of information. Any reliance on the material on this site is at
        your own risk. We are not under any obligation to review any Content
        posted by Our Users on Our Services, although We reserve the right to do
        so with or without notice, to prevent or rectify any alleged violations
        of this Agreement or any applicable law. We reserve all defenses made
        available to us by the Communications Act and any other applicable laws,
        rules, or regulations. We may refuse to accept or display the Content,
        and may remove or delete all or any portion of the Content at any time.
      </p>
      <p>
        This site may contain certain historical information and historical
        information necessarily is not current and is provided for your
        reference only. You understand that Repeddle is not responsible for the
        accuracy, usefulness, safety, or intellectual property rights of or
        relating to such and any Content, and that such Content is not the
        responsibility of Repeddle. We reserve the right to modify the contents
        of this site at any time, but we have no obligation to update any
        information on our site. You agree that it is your full responsibility
        to monitor changes to our site. You further understand and acknowledge
        that You may be exposed to Content that is inaccurate, offensive,
        indecent, or objectionable, and You agree to waive, and hereby do waive,
        any legal or equitable rights or remedies You have or may have against
        the Repeddle Parties with respect thereto.
      </p>
      <p>
        Any and all Content You upload is not considered confidential by
        Repeddle, and will be treated as public information, and You agree and
        understand is intended to be shared with other users of our Services as
        well as third parties, all in Repeddle’s sole discretion, and Repeddle
        has no control or liability over what other users do with your Content.
        You understand and agree that Repeddle will not be liable for any
        treatment of your Content as confidential and waive all rights with
        respect to any such claims of confidentiality. You acknowledge and agree
        that any questions, comments, suggestions, ideas, feedback or other
        information about the Site or the Service (“Submissions”), provided by
        you to Repeddle are non-confidential and Repeddle shall be entitled to
        the unrestricted use and dissemination of these Submissions for any
        purpose, commercial or otherwise, without acknowledgment or compensation
        to you. If you do not agree that your Content will be considered public
        and will not be considered confidential, you are oblige not to use the
        Service. Your Obligation: You are solely obligated for any and all
        information, descriptions, pictures, listings, data, text, music,
        videos, media, comments, or any other materials (“Content”) that you
        upload, post, publish, transmit, or display (“Post”) via the Service.
        Below mentions are examples of Content, Postings, or Use of the Service
        that is illegal or prohibited by Repeddle.
      </p>
      <p>
        You agree not to use the service to (1) provide any Content, Postings,
        or otherwise that are unlawful, harmful, violent illegal, infringing on
        third party rights, objectionable, pornographic, libelous, invasive,
        encouraging money laundering, gambling or any other unlawful or
        unwarranted behavior. (2) violate any law, rule, or regulation,
        including any anti-spam, data privacy, or other restriction that may be
        applicable to Your use of the Service (3) create any derivative works or
        reviser engineer any part of the Service, or put unreasonable load on
        the Service infrastructure or disrupts the networks connected to the
        Service (4) promote any illegal activity or enterprise (5) stalk,
        harass, bully, impersonate or solicit information from anyone,
        especially those that are under the age of legally permissible, in this
        case Fifteen(15), copy, scrape, harvest or use automated systems to
        collect contact information from the Service for use outside of those
        intended by this Terms of Use (7) sell or transmit anything you don’t
        have a right to sell or transmit under law or existing relationship (8)
        pose a privacy or security risk (9) infringe on anyone’s intellectual
        property (10) spam any users with email, junk mail, fraud, schemes, or
        the like (11) transmit or upload viruses, worms, or interfere with the
        Service, or (12) take any action or inaction which Repeddle, in its sole
        judgement, believes is questionable or could cause harm or liability.
        Repeddle in all it right may investigate, take legal action, or perform
        any other action it deems necessary or warranted in managing the
        Service, Your Content, Posting, or Use, without limitation, including
        preservation of such information for investigative purposes.
      </p>
      <RedTitle>4.1. Electronic Communications</RedTitle>
      <p>
        By Using Our Service, you agree that you may receive communications from
        Repeddle, including, but not limited to, newsletters, promotions,
        special offers, account reminders and updates. When you use our Services
        or send emails, text messages, and other communications from your any of
        your device to us, you are engaging in electronic communications with
        us. You consent to receive communications from us electronically, as
        thus emails, texts, mobile push notifications, or notices and messages
        from our site or through other Services provided on our Site, and you
        are allowed to retain copies of these communications for your records.
        You agree that all agreements, notices, disclosures, and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing.
      </p>
      <RedTitle>
        4.2. Modifications to Service, Credits, Offers and Prices
      </RedTitle>
      <p>
        Prices for products are subject to change without notice. Prices
        displayed on a product may not be the final price you pay. The total
        price is seen during checkout and this is determined by numbers of
        selected items, Delivery, Authentication, Cash handling fee or VAT (If
        Applicable), hence, you may be advised not to make final decision based
        on the product price seen or heard except if otherwise stated on our
        site, the total cost of your order is usually displayed during checkout,
        displayed product prices are reflected in your home currency, however,
        the total payable charges of ordered goods may be subject to change
        depending on third-party payment gateway exchange rate of your country’s
        current currency.
      </p>
      <p>
        We reserve the right at any time to modify or discontinue the Service
        (or any part or content thereof) without notice at any time. We shall
        not be liable to you or to any third-party for any modification, price
        change, suspension or discontinuance of the Service. Repeddle may offer
        promo or credits to existing users at intervals, for any reason, at the
        sole discretion of Repeddle, including, but not limited to, users who
        refer new users to the Service (who have not previously used the
        Service) using the invite code provided by Repeddle to those existing
        users and may also make certain credits available to the new users who
        are referred by an existing user. This promotion may be subjected to
        some selected goods, products or brands with certain terms and
        conditions. The requirements to earn, the number of, and value of, these
        credits (if any) will be determined by Repeddle. Repeddle Credits are
        not redeemable for cash or cash equivalents, and are non-transferrable
        or applicable to prior purchases and cannot be sold, made available to
        the general public, or acquired via public distribution.
      </p>
      <p>
        You consent that promo codes are applied only on physical product and
        gift cards are not eligible for promo codes. Suspended accounts are
        prohibited from using Repeddle Credits during suspension. Other
        restrictions may apply. You accept that we may choose to cancel credits
        or any sort of coupon, promo and offers at any time, or upon fourteen
        (14) day notice to You at our sole discretion. On checkouts, you may be
        required to apply a given coupon, referral or promo code. In some
        instance, it may apply automatically and you will be notified by price
        change on checkout. Repeddle may modify, update, or terminate this
        program without notice, and Repeddle reserves the right to enact any
        rules and regulations with respect to the program, including value,
        earning and use limits, as well as invalidation or expiration of
        credits. You may apply credits subject to the terms in which we grant
        the credit. Credits may expire prior to your use, cancelled at any time.
        Understand that we reserve the right to withdraw from any promotion or
        similar incentives of any sort on our website without prior notice and
        claims may not be hold against us for any withdrawal herein.
      </p>
      <RedTitle>5. Intellectual Rights</RedTitle>
      <p>
        You accept Our App and Website contains Images, Videos and description
        that are third party’s contents that is protected by trademark, patent,
        copyright, trade secret and other proprietary rights and laws referred
        to as “Content”. All rights to Repeddle’s intellectual properties are
        solely reserve to Repeddle. No modification, distribution, copying,
        recreate, derivate, record, store of any content related to Repeddle
        service is permissible. Involving in any act mentioned above is regarded
        by Repeddle as breach of intellectual property right.
      </p>
      <p>
        By using this service, you expressly accept not to have any exposed
        content of Repeddle intellectual property not authorized by this terms
        in your possession. The use of the content provided in this service
        rather than what it is specifically approved for, is strictly
        unacceptable, hence deem to be intellectual property, any proceed yield
        from the use of Repeddle’s intellectual property, trademark or content
        shall be independently of Repeddle’s advantage. All third party
        products, service, logos and brand names appearing on our Websites or
        any of our Platforms are independent trademarks of their respective
        owners. Except otherwise mentioned, Repeddle has no affiliation,
        connection, endorsement or endorses any trademark displayed on it online
        or physical platforms.
      </p>
      <p>
        Repeddle respects the intellectual property of others. Should you
        believe, in good faith, that any materials on the Services infringe upon
        your copyrights, you may submit a Copyrights Infringement notice through
        our email according to the applicable process:
        <div>Repeddle</div>
        <div>Attention: Copyrights Infringement Notice</div>
        <div>Email: support@repeddle.com or support@repeddle.co.za</div>
      </p>
      <p>
          When submitting a Notice of Alleged Infringement, please provide us
        with this information:
        <ul>
          <li>
            A physical signature of the person authorized to act on behalf of
            the owner of the copyright interest;
          </li>
          <li>
            A description of the copyrighted work that you claim has been
            infringed upon;
          </li>
          <li>
            A description of where the material that you claim is infringing is
            located on the site;
          </li>
          <li>Your address, telephone number, and email address;</li>
          <li>
            A statement by you with a supporting evidence (the actual original
            material) that you have a good-faith belief that the disputed use is
            not authorized by the copyright owner, its agent, its source or the
            law;
          </li>
          <li>
            A statement by you, made under penalty of perjury, that the above
            information in your notice is accurate and that you are the
            copyright owner or authorized to act on the copyright owner’s
            behalf.
          </li>
        </ul>
      </p>
      <RedTitle>
        5.1 Representing Yourself and Store/ Infringement Warranties
      </RedTitle>
      <p>
        <ol>
          <li>
            For the purpose of representing your products and store in good
            faith and transparency, Users warrants that they are original owner
            and or beneficiary to the work they post on Repeddle, or have legal
            right and binding to the title or a trademark.
          </li>
          <li>
            Represent that the product listed or sold does not infringe any
            laws, copyrights, trademarks or intellectual property be it third
            parties or solely claims. That the Seller has the right and full
            legal capacity to enter and honor its responsibility and obligations
            in regard to the services provided and operates an account on our
            platform.
          </li>
          <li>
            That in any event, Repeddle is not liable to procure any license
            needed to fulfill the Seller’s responsibility and services. If in
            any case Seller contravene license laws and regulation, Seller
            expressly indemnify Repeddle against such actions and Repeddle will
            not be liable of any cost.
          </li>
          <li>
            Users warrants that to her best knowledge, is unaware of any breach
            of contract, claim or penalty for infringement of copyright,
            trademark law or intellectual property against them ensue out of
            production, designing or manufacturing of products as at when
            entering to this agreement, and agrees to inform Repeddle with
            immediate effect should such claim ensue.
          </li>
          <li>
            {" "}
            Seller agrees that in no circumstances will the listing, selling and
            shipping her product to our customers through our platform will
            infringe any rights or third parties’ intellectual properties
            whatsoever, and that all parties involved legally has agreed and
            authorize to this agreement.
          </li>
          <li>
            Seller warrants that all listed products are fit of use for the
            intended purpose and comply with applicable laws and standards.
          </li>
          <li>
            That all information provided on Repeddle’s platform in regards to
            their account, products, designs, production, and listings are true,
            correct and updated to the best of their knowledge, that the product
            they sell on our platform will be of standard good quality and
            useable. Seller accepts that their production, material, fabrics,
            designs, are authentic and free from defect. With the continual use
            of Repeddle’s platform, Seller agrees that this agreement remains
            valid and they are legally bind by this agreements and other
            policies and guideline herein.
          </li>
        </ol>
      </p>
      <RedTitle>6. Products And Services</RedTitle>
      <p>
        Certain products or services may be available exclusively online through
        the App and Website. These products or services may have limited
        quantities and are subject to return only according to our Return
        Policy. As a seller, you agree you have made every effort to display
        accurately the colors, description and images of our products that
        appear in your store. For all Users, we cannot guarantee that your
        computer monitor's display of any color will be accurate. We reserve the
        right, but are not obligated, to limit the sales of products or Services
        to any person, geographic region or jurisdiction. We may exercise this
        right on a case-by-case basis. We reserve the right to limit the
        quantities of any products or services that is offered. All descriptions
        of products or product pricing are subject to change at any time without
        notice, at the sole discretion of the seller or us. We reserve the right
        to discontinue any product at any time. Any offer for any product or
        service made on this site is void where prohibited. We do not warrant
        that the quality of any products, services, information, or other
        material purchased or obtained by you will meet all your expectations,
        or that any errors in the Service will be corrected.
      </p>
      <RedTitle>6.1 Repeddle Buyer and Seller Protection</RedTitle>
      <p>
        We make cautious effort to protect the interest of Repeddle users. Using
        Repeddle Buyer’s and Seller’s protections, we try to eliminate
        fraudulent activities to a great extent making sure the buyers receive
        their purchased goods and sellers get paid. For we to guarantee that
        your transaction is protected, you accept that all transactions
        including full payments of your purchase must be made within Repeddle’s
        App and Website. Buyers must use only the payment methods and methods of
        delivery provided in line with our Terms of Use. Buyers can only pay for
        their purchase with the available payment methods offered on Repeddle’s
        App and Website checkout. The Repeddle protections for both Buyers and
        Sellers against potential fraud works as follows:
        <ol>
          <li>
            {" "}
            Repeddle has in App and Website message system which makes
            communications seamless between Sellers and Buyers. We advise Buyers
            and Sellers to communicate regarding product information to ensure
            Buyers get all necessary information that will help them make an
            informed purchase decision before buying. This will help eradicate
            or minimize disputes between Sellers and Buyers.
          </li>
          <li>
            After a complete order is made and payment is successful through
            checkout, the amount paid for the product is deposited in Repeddle’s
            account. The Seller will package and ship the product and confirms
            the delivery of the goods by triggering delivered button after
            completing the shipping process on the order.
          </li>
          <li>
            {" "}
            When the order is shipped, Repeddle will wait for the Buyer to
            confirm the receipt of the delivery.
          </li>
          <li>
            When Buyer receives the order, they inspect and is satisfied with
            the order, they will trigger the received button on the appropriate
            order. Repeddle will less its commission and remit the purchased
            order balance to the seller’s Repeddle wallet. With this process,
            Buyers get what they paid for and Sellers gets the payment for their
            sold goods. NOTE: Transaction taken outside Repeddle’s App and
            Website is not in any way protected.
          </li>
        </ol>
      </p>
      <RedTitle>Accuracy Of Billing, Payment and Account Information</RedTitle>
      <p>
        In order to make a purchase using the Services, you must have a valid
        payment method which may include credit card and Repeddle wallet. Buyers
        may pay for products using approved payment methods, and Repeddle will
        receive payment from Buyer on Seller’s behalf. You are responsible for
        paying all fees and taxes (if applicable) associated with your purchase
        or service used when using the Services.You warrant that you are
        eligible, authorized and your credit card has sufficient funds to pay
        for the goods ordered, and that incurred service charges resulting to
        the use of Our Service can fully be paid for. You agree to provide
        current, complete and accurate purchase and account information for all
        purchases made. You agree to promptly update your account and other
        information, including your email address and credit card numbers and
        expiration dates, so that we can complete your transactions and contact
        you as needed. We will not be hold accountable or responsible for any
        declined order or loss of goods as part of your negligence or inadequate
        supply of correct information. By submitting payment information to
        Repeddle, you authorize Repeddle’s payment service third party to store
        that information and with you confirmation, charge you for any purchased
        product or services. By using a third party payment service offered by
        Repeddle, including but not limited to PayPal, you are subject to their
        terms of use and privacy policies. For more about optional tools,
        see section 8 below.
      </p>
      <RedTitle>7.1 Fees/Commission</RedTitle>
      <p>
        Repeddle does not charge any fees for you to sign-up as a seller or for
        listing your products to sell, but shall collect commission from
        customer’s payments for a successful product(s) sold prior to paying
        over funds to seller. Repeddle’s commission is set at a percentage of
        the Recommended Rate Price (RRP) including delivery charge, without
        hidden charges and are laid out below:-
      </p>
      <ul>
        <li> Repeddle Commission: 2.9%,</li>
        <li>Third Party Payment Transaction Fee: 5%</li>
        <li>
          Total commission chargeable per product upon successful sale = 7.9%.
        </li>
      </ul>
      <p>
        These commission will be applied to each product purchased by a Buyer
        and we will less the due percentage from the payout of the Seller. We
        may change or discontinue, temporarily or permanently, some or all fees
        for the Service, and such changes will be effective upon Our revision of
        the fees/commission structure. Payments will be processed by a
        third-party payment provider, and in accordance with their terms of
        service, and not by us. In other to fulfill our obligation to you Seller
        and Buyer, we reserve the right to add or change payment providers
        should the need arises. We may or not notify you of any new payment
        provider in which case, your continued use of the Service, and upon
        receipt of such notice constitutes acceptance of such addition or change
        as well as your acceptance of their fees and terms of use. You
        acknowledge that we may share the information you provided during sign
        up to our platform with any payment providers we select, solely for the
        purpose of enabling them to provide us with payment processing services
        to support the Seller and Buyer features you use.
      </p>
      <p>
        You agree to take full responsibility of any Taxes that may apply for
        your product sold with the relevant government bodies of your country.
        Taxes liability for products sold solely lies with the seller. Repeddle
        will not be liable for any issues relating to your tax obligation that
        might ensue.
      </p>
      <p>
        Except as otherwise provided in these Terms of Use, you agree that we
        are not obligated to determine whether Taxes apply, and we may not be
        responsible to collect, report, or remit any Taxes arising from any
        transaction on your behalf. If a Taxing authority requires you to pay
        taxes and informs us, we will immediately notify you of this development
        and you are oblige to immediately fulfill your Tax obligation. Should
        you refuse to do so, according to the applicable law in your
        jurisdiction, we may suspend your account until you honor your tax
        obligation. Please note, your earnings are based on the listing price
        and actual earnings will vary based on the final order price, seller
        discounts, and any other applicable taxes and discounts that may/or if
        apply.
      </p>
      <RedTitle>8. Delivery, Cancellation, Return.</RedTitle>
      <p>
        Buyers are responsible for shipping costs incurred in respect with their
        purchase except otherwise, where free delivery may be offered by the
        seller. For the purpose of reaping the benefit of free delivery on some
        selected stores/products, we encourage buyers to look out for
        “Re:Bundle” and make the best of the 2hours window opportunity of free
        delivery after the 1 st purchase on the store that activates
        “Re:Bundle”. Product that falls under standard delivery may take lesser
        time to be delivered than some other products. As delivery estimated
        time varies, delivery estimated time may be allocated to applicable
        order during checkout (if available from delivery partner), you agree
        that the delivery times are estimated as we do not guarantee exact time
        of delivery.
      </p>
      <p>
        Depending on Product, chosen Payment method and Cleared payment, below
        are our possible delivery time structures:
        <ul>
          <li>
            {" "}
            We expect that Delivery may take within 3-5 or 5-9 working days
            between 8am -5pm depending on delivery choice and carriers.
          </li>
          <li>
            Some delivery partners may offer same day delivery if available (at
            a cost) and if offered by a seller.
          </li>
        </ul>
        Repeddle offers several delivery options for user’s convenience through
        our third party delivery partner(s). We only deliver to physical
        addresses. Please ensure to provide your choice of delivery address or
        pick/drop off point respectively during checkout and we do not deliver
        to PO Box. Please be sure to look out for delivery details, “guidelines
        and policy” of our delivery partner for more information on how their
        delivery system works.
      </p>
      <p>
        If a fault or negligence on your part causes delivery delays, please
        understand that we shall not be held responsible for any charges, loss,
        expense, damages, cost or liabilities. Be aware that some goods are
        heavier, larger or weighs more than others, products of this volumes may
        be subject to delivery charges according to the weight and guideline of
        our third party delivery partner(s).
        <ul>
          <li>
            On a successful delivery, we require you to collect your receipt,
            inspect your goods for damages, errors or defects and communicate to
            us within 48hrs in the case of any defect, we require you to keep
            the goods in its current delivered condition for the seller or our
            representative (where apply) to inspect and confirm. If you do not
            inspect and communicate with us within 48hrs, you agree that we will
            not be liable to you. To an extended permitted by law, we will not
            be liable for any cost, losses, damages, liabilities, expenses or
            charges as a result of tear and wear after delivery.
          </li>
          <li>
            We may require you to provide us with substantial information or
            evidence regarding rectifying a product to help us reach a
            reasonable decision and agreement, we may also require that you pay
            for service, delivery, refund or replacement fees in line with
            Repeddle current charges and/or we may directly charge the
            reasonable amount to your card or the provided payment details at
            the point you place your order or sell a product if that be the
            case, should we at our own reasonable discretion decide to refund,
            repair or replace your product.
          </li>
          <li>
            We urge you to sign for your delivery when receiving and advise that
            you keep your receipt for future references.
          </li>
          <li>
            You accept to adhere to manufacturers instruction reflected on
            product pamphlet, you also agree to adhere to strict usage according
            to those instructions. We will not be liable to you for not
            following instruction or using product in accordance with product
            usage.
          </li>
          <li>
            In the case that we are unable to deliver to some certain areas or
            location due to accessibility or safety, the delivery company, the
            seller or we will contact you through the contact information
            provided at the time you place your order, then you can reschedule
            to deliver to an alternative location if the delivery company
            accepts such alternative. Please be sure to be available to receive
            your delivery in timely manner and in a safe and accessible location
            that you have provided. In the case you are not available to receive
            the delivery as scheduled, you are advised to inform the delivery
            company through their contact details or the seller within 24hours
            of scheduled delivery, should you not reach any of this contacts
            only then you can contact Repeddle support for assistance,
            afterwards then you can reschedule or we may require you to assign a
            trusted representative to receive your delivery with a valid
            Identification, Invoice/Proof of payment and purchase ID. Please
            understand that the delivery company may not deliver your goods to
            this representative if they don’t have this documents or don’t meet
            these requirements.
          </li>
          <li>
            We encouraged you to take adequate measures when opening your
            package so as to avoid damage.
          </li>
          <li>
            Lookout for instruction on the package or instruction pamphlet if
            available to help you avoid any damages.
          </li>
          <li>
            {" "}
            Your order may be packaged by our third party delivery partner(s)
            with standard delivery packages, any specifics or customized
            packaging you require may be subjected to extra cost.
          </li>
          <li>
            Upon delivery of purchased goods, you agree that all risk is wholly
            transferred to you, or in the case of delay of delivery due to your
            own circumstances, negligence or breach of contract, transfer of
            risk still stands to you from the date delivery should have taken
            place, hence, we are not liable for damages or loss of goods from
            the date/time when risk is transferred to you. You also accepts that
            the risk of loss for all products you order is expressly transferred
            to you upon the delivery to the carrier and not Repeddle except
            stated otherwise.
          </li>
          <li>
            In the course of delivery, if delivery is not successfully received
            by you or your qualified representative due to negligence on your
            part in our first attempt, or within the stipulated period of
            delivery allocated to your order, we may exercise rights that are
            legally bound to us. You agree that we reserve the right to
            discontinue with this agreement and withdraw from delivery or
            providing you with this product. We will communicate our decision to
            you via the provided contact details and cancel, hence, a refund
            will be made through your selected payment method, less our
            processing fee and administration charges which may include but not
            limited to (storage fees, returning fee and any charges incurred for
            delivery attempts).
          </li>
        </ul>
      </p>
      <RedTitle>8.1 Cancellation:</RedTitle>
      <p>
        Depending on locations, Repeddle delivery partner(s) aim to deliver your
        goods as quickly reasonable as possible. We reserve the right to cancel,
        refuse to honor or fulfill an order in any case of suspected fraud which
        include but not limited to (Credit/Debit Card, User Account or Seller
        Profile).
        <ul>
          <li>
            Any return as a result of damage from a buyer will be declined.
          </li>
          <li>
            You accept that in the event we do not receive authorization and
            payment verification, your order may be canceled.
          </li>
        </ul>
      </p>
      <RedTitle>8.2 Returns</RedTitle>
      <p>
        You may return a product after delivery, however, Repeddle allows you to
        return a product in limited circumstances. If the product you receive is
        not as described at the time of purchase, then you may log a return by
        using the “LOG A RETURN” tab on your order reporting the issue through
        the App or Website, in each case within three days after delivery (as
        determined by the tracking information and delivery) of the product. For
        more detail about returns after delivery, please refer to our Return and
        Refund Policy. By Reference, our Return and Refund Policy forms part of
        our Terms of Use.
      </p>
      <RedTitle>9. Optional Tools</RedTitle>
      <p>
        We may provide you with access to third-party tools over which we
        neither monitor nor have any control nor input. You acknowledge and
        agree that we provide access to such tools “as is” and “as available”
        without any warranties, representations or conditions of any kind and
        without any endorsement. We shall have no liability whatsoever arising
        from or relating to your use of optional third-party tools. Any use by
        you of optional tools offered through the Service is entirely at your
        own risk and discretion and you should ensure that you are familiar with
        and approve of the terms on which tools are provided by the relevant
        third-party provider(s). We may also, in the future, offer new services
        and/or features through the App and Website including but not limited
        to, the release of new tools and resources. Such new features and/or
        services shall also be subject to these Terms of Use.
      </p>
      <RedTitle>10. Users Comments, Feedback and Other Submissions</RedTitle>
      <p>
        If, at our request, you send certain specific submissions (for example
        contest entries) or without a request from us you send creative ideas,
        suggestions, images, proposals, plans, or other materials, whether
        online, by email, by postal mail, or otherwise (collectively,
        &#39;comments&#39;), you agree that we may, at any time, without
        restriction, edit, copy, publish, distribute, translate and otherwise
        use in any medium any comments, feedback, contents or other submissions
        you forward to us. We are, and shall be under no obligation (1) to
        maintain any feedback other submissions or comments in confidence; (2)
        to pay compensation for any feedback, comments or other submissions; (3)
        to respond to any feedback, comments or other submissions; (4) You agree
        that you may receive telephonic, emails or electronic communications
        from us, affiliate or our third party when you visit our website or we
        receive emails, SMS or telephonic communication from you in regulation
        with our privacy policy. We may, but have no immediate obligation to,
        monitor, edit or remove comments from our App or Websites that may be
        triggering to Users or may be considered unlawful, offensive,
        threatening, libelous, defamatory, pornographic, obscene or otherwise
        objectionable or violates any parts of intellectual property. You also
        agree that your comments will not violate any right of any direct user,
        third-party, including copyright, trademark, privacy, personality or
        other personal or proprietary right. You further agree that your
        comments will not contain libelous or otherwise unlawful, abusive
        bulling or obscene material, or contain any computer virus or other
        malware that could in any way affect the operation of the Service or any
        related App and Website. You will not use a false e‑mail address,
        pretend/impersonate to be someone other than yourself, or otherwise
        mislead us or third-parties as to the origin of any comments. You are
        solely responsible for any comments you make and their accuracy. We take
        no responsibility and assume no liability for any comments posted by you
        or any third-party.
      </p>
      <RedTitle>10.1 Personal Information</RedTitle>
      <p>
        Your submission of personal information through the Service is governed
        by our Privacy Policy. Please view our Privacy Policy as it forms part
        of our Terms of Use.
      </p>
      <RedTitle>11. Errors, Inaccuracies and Omissions</RedTitle>
      <p>
        Occasionally there may be information on our App and Website or in the
        Service that contains typographical errors, Sellers agree to ensure to
        take all rational measures to carefully reflect these information(s), to
        avoid inaccuracies or omissions that may relate to product descriptions,
        pricing, promotions, coupons, vouchers, offers, referral bonuses,
        product shipping charges, transit times and availability. We reserve the
        right to correct any errors, inaccuracies or omissions, and to change or
        update information or cancel orders if any information in the Service or
        on any related App and Website is inaccurate at any time without prior
        notice (including after you have submitted your order). We undertake no
        obligation to update, amend or clarify information in the Service or on
        any related App and Website, including without limitation, pricing
        information, except as required by law. No specified update or refresh
        date applied in the Service or on any related website should be taken to
        indicate that all information in the Service or on any related website
        has been modified or updated.
      </p>
      <RedTitle>12. Prohibited Use</RedTitle>
      <p>
        In addition to other prohibitions as set forth in these Terms of Use,
        you are prohibited from using the Service, App or Website and its
        content as follows:
        <div>(a) For any unlawful purpose;</div>
        <div>
          (b) To solicit others to perform or participate in any unlawful acts;
        </div>
        <div>
          (c) To violate any international, federal, provincial or state
          regulations, rules, laws, or local ordinances;
        </div>
        <div>
          (d) To infringe upon or violate our intellectual property rights or
          the intellectual property rights of others;
        </div>
        <div>
          (e) To harass, bully, abuse, insult, harm, defame, slander, threaten,
          disparage, intimidate, Carry any weapons, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability;
        </div>
        <div>(f) To submit false or misleading information;</div>
        <div>
          (g) To upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or of any related website, other websites,
          or the Internet;
        </div>
        <div>
          (h) To collect or track the personal information of others or stalk
          others;
        </div>
        <div>(i) To spam, phish, pharm, pretext, spider, crawl, or scrape;</div>
        <div>(j) For any obscene or immoral purpose;</div>
        <div>
          (k) To interfere with or circumvent the security features of the
          Service or any related website, other websites, or the Internet.
        </div>
        <div>
          (l) rent, lease, lend, sell, redistribute, license, sublicense or
          access to any portion of the Services;
        </div>
        <div>
          (m) Frame or “mirror” any part of the Services, without our prior
          written authorization or use meta tags or code or other devices
          containing any reference to us in order to direct any person to any
          other website for any purpose;
        </div>
        <div>
          (n) Modify, adapt, translate, reverse engineer, decipher, decompile or
          otherwise disassemble any portion of the Services or any software used
          on or for the Services;
        </div>
        <div>
          (o) Cause any third party to engage in the restricted activities
          above;
        </div>
        <div>(p) Use the Website and Services for any unlawful purposes;</div>
        <div>
          (q) Sell, resell, sublicense, distribute, transfer, copy, reproduce,
          publicly display, duplicate, or download (other than page caching),
          the Website or Services, or any part thereof;
        </div>
        <div>
          (r) Collect information (including without limitation, any product
          listings, descriptions, photos, images, or prices), as listed on the
          Website or included in the Services unless as permitted by Section
          “Communications and Other Content”;
        </div>
        <div>
          (s) Adapt, modify and/or make any derivative modifications to the
          Website or the Information, or any part thereof;
        </div>
        <div>
          (u) Download or copy any account and/or information from the Website
          or Services, or any part thereof, for the benefit of another person,
          entity, vendor and/or merchant;
        </div>
        <div>
          (v) Use any meta tags or any other “hidden text” utilizing Repeddle’s
          name or trademarks or any third party’s name or trademarks without the
          express written consent of Repeddle or the applicable third party.
        </div>
        <div>
          (w) Publish and/or use unlawful, threatening, abusive, defamatory,
          libellous, vulgar, obscene, profane, indecent or otherwise
          objectionable, language, text, photos, graphics or howsoever otherwise
          publications, on the Website;
        </div>
        <div>
          (x) Collude against another person in restraint of trade and
          competition;
        </div>
        <div>
          (y) Create a hyperlink to the Website, or any page of the Website,
          without Repeddle’s express written permission; or
        </div>
        <div>
          (z) Imply affiliation with or endorsement or sponsorship by Repeddle,
          or cause confusion, mistake, or deception in connection therewith. We
          reserve the right to terminate your use of the Service or any related
          website for violating any of the prohibited uses.
        </div>
      </p>
      <RedTitle>12.1. Prohibited Products: </RedTitle>
      <p>
        Furthermore, we prohibit the listing of certain products on our service
        that may contravene the rules of law. In general, secondhand or
        pre-owned products that are in great, good and clean condition are
        totally allowed to be listed on Repeddle. However, all products must be
        accurately, clearly describe and represented. Any liquid products must
        be well packaged to avoid damage and must be new in their original
        package and sealed. Makeup and personal care products must be new,
        alcohol and aerosol free. Below are some of the prohibit product that
        you are not allowed to list:
        <ul>
          <li> Tobacco</li>
          <li>Alcohol and Food</li>
          <li>Motor Vehicles</li>
          <li>Medicines, Healthcare Products and Medical Devices</li>
          <li>Weapons, Firearms and Military Equipment</li>
          <li>Fakes, Replicas and Unauthorized Goods</li>
          <li>Any illegal and Law Contradicting Products</li>
          <li>Nudity, Pornography and Adult Content Materials</li>
          <li>Digital and Non Tangible Products</li>
          <li>Advertisements</li>
          <li>Non Repeddle Gift cards and Vouchers</li>
          <li>
            Drugs and toxic substances such as: Arsenic; Beryllium; Cyanide;
            Fluorine; Hydrogen Selenide; Infectious Substances containing
            Micro-Organisms or their Toxins which are known to cause (or are
            suspected of causing) disease: Mercury; Mercury Salts; Mustard Gas;
            Nitrobenzene; Nitrogen Dioxide; Pesticides; Serum and Vaccines
          </li>
          <li>Any Explosive Material or Chemicals</li>
          <li>Radioactive Materials</li>
        </ul>
        Please be advised not list any prohibited products mentioned here. You
        accept that listing any of the above mentioned product is a breach of
        our Terms of Use and may lead to temporal account suspension or
        permanent deactivation. You expressly accept to indemnify Repeddle of
        any damage or loss that may arise as the breach of this Terms of Use. In
        our sole discretion, we may update these list from time to time, please
        ensure to always check the list of prohibited product for any new
        updates.
      </p>
      <RedTitle>13. Disclaimer Of Warranties; Limitation On Liability</RedTitle>
      <p>
        Repeddle in its reasonable capacity will strive to ensure the
        availability of its services, and accuracy of the contents and
        information on the App and Website. You may report to us in writing via
        email at support@repeddle.com or support@repeddle.co.za of any related
        errors or malfunctions. Repeddle do not make warrant that the results
        obtained from the use of the service will be; (A) accurate, reliable,
        uninterrupted, timely, secure or error-free, (B) the quality of any
        goods, services, information, or other material purchased or obtained by
        you through the service will meet your expectations, (C) you will be
        able to sell or purchase any items through the service or that the
        service will otherwise meet your requirements.
      </p>
      <p>
        You agree that from time to time we may remove the service for
        indefinite period of time or cancel the service at any time, without
        notice to you. You expressly agree that your use of, or inability to
        use, the service is at your sole risk. The service and all products and
        services delivered to you through the service are (except as expressly
        stated by us) provided “AS IS” and “AS AVAILABLE” for your use, without
        any representation, warranties or conditions of any kind, either express
        or implied, including all implied warranties or conditions of Seller
        ability, Sellable quality, fitness for a particular purpose, durability,
        title, and non-infringement. Repeddle is not liable for (i) any negative
        or critical comments that may be posted by other Users through the
        Services or any content posted on our service; (ii) purchases, sales, or
        other obligations that may arise between Users and any cost of
        substitute service or goods; (iii) any third party personally
        identifiable information you upload or provide to us pursuant to the
        Services or any damages that result through Your use of Our Services;
        (iv) any of the Third Party Service(s) You may be provided pursuant to
        Your use of the Services. You are fully aware that you are solely
        responsible for your interactions with other users, including any
        purchase or sale transactions, and you agree Repeddle will not be liable
        or responsible with respect to such purchases, interactions, or sales.
        We reserve the right but not obligated to become involved in any dispute
        between users.
      </p>
      <p>
        Repeddle expressly disclaims all warranties of any kind be it express,
        statutory or implied in any case. Repeddle, Our directors, officers,
        employees, affiliates, agents, contractors, interns, suppliers, service
        providers or licensors shall not be liable for any injury, loss, claim,
        or any direct, indirect, incidental, punitive, special, or consequential
        damages of any kind, including, without limitation lost profits, lost
        revenue, lost savings, loss of data, replacement costs, or any similar
        damages, whether based in contract, tort (including negligence), strict
        liability or otherwise, arising from your use of any of the service or
        any products procured using the service, or for any other claim related
        in any way to your use of the service or any product, including, but not
        limited to, any errors or omissions in any content, or any loss or
        damage of any kind incurred as a result of the use of the service or any
        content (or product) posted, transmitted, or otherwise made available
        via the service, even if advised of their possibility not for more than
        the greater amount of commissions that you have paid to Repeddle as a
        seller in the last six (6) months. Because some states or jurisdictions
        do not allow the exclusion or the limitation of liability for
        consequential or incidental damages, in such states or jurisdictions,
        our liability shall be limited to the maximum extent permitted by
        applicable laws. The limitations of this section will not apply to any
        liability that cannot be excluded or limited by law.
      </p>
      <RedTitle>14. Indemnification</RedTitle>
      <p>
        You expressly agree to indemnify, defend and hold harmless Repeddle and
        our parent, subsidiaries, affiliates, partners, officers, directors,
        agents, contractors, licensors, service providers, subcontractors,
        suppliers, interns and employees, harmless from any claim or demand,
        including reasonable attorneys’ fees arises from your action or
        inactions, made by any third-party due to or arising out of your breach
        of these Terms of Use, use or (misuse) of, reliance upon, linked third
        party, unlawful activity, inability or ability to use Our service, or
        the documents they incorporate by reference, or your violation of any
        law, account infringement or the rights of a third- party.
      </p>
      <p>
        Repeddle shall not be held responsible or suffer any cost or expense as
        a result of Seller liabilities, and shall not be liable for any fee,
        legal or otherwise prior to implementing right of indemnity against a
        User, and Users has agreed to indemnify Repeddle in any, and all area of
        User’s faults. The indemnities provided herein shall survive the
        termination of this agreement. Repeddle shall not be liable for any
        cost, penalty, judgement, liabilities, proceedings, expenses, claims,
        court fee/tariff or damage whatsoever to the most reasonably beneficial
        to Repeddle ensued in any form as a result of Users behavior (act, error
        or omission) while running an account or transacting on Repeddle’s
        platform, this includes but not limited to Seller partners,
        representative, contractor/subcontractor or employees, be it in form of
        misconduct, misdeed wrongdoing, illegality, lawlessness, or otherwise.
      </p>
      <p>
        Users agree to indemnify Repeddle in this agreement for all mentioned in
        this section and for any loss ensued from recklessness or otherwise in
        the form of loss of property, any loss of life, damages, personal
        injury, warrant and breach of any representation or laws. You assume
        certain risks in using a marketplace service such as Repeddle. Repeddle
        is not involved with, nor provides a warranty for, any transaction
        between Buyer and Seller, nor has title to any Items, and may not be the
        Buyer or Seller in any transaction. You as a Buyer and/or Seller assume,
        agreement, and understand You bear all of the risks in selling or
        purchasing of items on the Service.
      </p>
      <p>
        To allow the effect of indemnification in this Agreement and Terms,
        Repeddle shall transfer any and all cost or claim incur or file against
        Repeddle to User and shall step down for Users to take her place as
        agreeable between the Users and Repeddle in serving such claims as
        reasonably obligated.
      </p>
      <RedTitle>15. Severability</RedTitle>
      <p>
        In the event that any provision of these Terms of Use is determined to
        be unlawful, void or unenforceable, such provision shall nonetheless be
        enforceable to the fullest extent permitted by applicable law, and the
        unenforceable portion shall be deemed to be severed from these Terms of
        Use, such determination shall not affect the validity and enforceability
        of any other remaining provisions.
      </p>
      <RedTitle>16. Termination</RedTitle>
      <p>
        These Terms of Use are effective unless and until terminated by either
        you or us. You may terminate these Terms of Use at any time by notifying
        us that you no longer wish to use the Services, or when you cease using
        our App and Website. Repeddle will not have any liability whatsoever to
        you for any suspension or termination. The obligations and liabilities
        of the parties incurred prior to the termination date shall survive the
        termination of this agreement for all purposes including ownership,
        success fees, warranty disclaimers, indemnity and limitations of
        liability. If in our sole judgment you fail, or we suspect that you have
        failed, to comply with any term or provision of these Terms of Use, we
        also may terminate this agreement at any time without notice and you
        will remain liable for all amounts due up to and including the date of
        termination; and/or accordingly may deny you access to our Services (or
        any part thereof). Furthermore, we may suspend or terminate the Services
        or Your account at Our discretion without explanation, notice, and
        liability to Repeddle including removing and discarding any items or
        content within the Service for any reason, in our capacity, we may
        strive to provide a timely explanation. Any suspected fraudulent,
        abusive or illegal activity that may be grounds for termination of your
        use of Service, may be referred to appropriate law enforcement
        authorities.
      </p>
      <RedTitle>17. Third Party Users</RedTitle>
      <p>
        Repeddle offers a platform where African Innovative Gen-z and
        Millennials recreate, resell and buy secondhand fashion for the benefit
        of the planet and our environment, these users form part of Repeddle
        independent “third party partners.” Except stated otherwise, Repeddle is
        not a direct buyer nor seller of products sold by independent Sellers,
        we however facilitate transactions between buyers and sellers. Sellers
        agree to all rights necessary to sell the products they make available,
        and will describe such products truthfully, accurately, and completely.
        Buyers are solely responsible for reading and reviewing the entire
        products listing before making any offer or purchase. The contract to
        purchase is entirely between Buyer and Seller. In accordance to Consumer
        Protection Act, 68 of 2008 (the “CPA”), Repeddle may help facilitate
        ELIGIBLE returns between Seller and Buyer and refund buyers after a
        successful return has been made to Seller. In some cases, Buyers may
        message Sellers directly through the Repeddle private message system
        with regard to their concerns for return and both party may reach a
        suitable agreement. In these instance, Repeddle does not take part in
        the return process but may help facilitate approved refund if need be.
        Please refer to our Return and Refund Policy to ensure all Terms are
        met. In the place where a buyer has message a seller about a return and
        there’s any contestation between both parties, Repeddle may interfere,
        or is authorize to attempt to resolve this to a satisfactory degree
        between both parties. However, Repeddle is not mandatorily or under any
        obligation to do so.
      </p>
      <RedTitle>18. Entire Agreement</RedTitle>
      <p>
        The failure of us to exercise or enforce any right or provision of these
        Terms of Use shall not constitute a waiver of such right or provision.
        These Terms of Use and any policies or operating rules posted by us on
        our App and Website or in respect to the service, constitutes the entire
        agreement and understanding between you and us and govern your use of
        the service, superseding any prior or contemporaneous agreements,
        communications and proposals, whether oral or written, between you and
        us (including, but not limited to, any prior versions of the Terms of
        Use. Any ambiguities in the interpretation of these Terms of Use shall
        not be construed against the drafting party.
      </p>
      <RedTitle>19. Governing Law</RedTitle>
      <p>
        All parties shall comply with all laws, rules, and regulations
        applicable to this Agreement. The General Terms of use and any separate
        agreements whereby we provide you services will be governed by and
        construed and enforced in accordance with the laws of Federal Republic
        of Nigeria.
      </p>
      <p>
        It is of Repeddle’s interest to amicably resolve disputes that may arise
        between users or the use of the service. Please use the respective
        contacts below to email support as applies within your jurisdiction. If
        the respective dispute resulting from General Terms or Agreement could
        not be settled by negotiations, then depending on the nature of the
        matter, the dispute will be finally solved by Lagos Multi Door Court
        House, if any provision of the General Terms is held to be
        unenforceable, the parties will substitute for the affected provision an
        enforceable provision that approximates the intent and effect of the
        affected provision.
      </p>
      <RedTitle>19.1. South African Users:</RedTitle>
      <p>
        {" "}
        Notwithstanding anything to the contrary in the rest of the Agreement,
        the following takes precedence with respect to South African Users: Any
        dispute arising from or in connection with this Contract shall be
        finally resolved in accordance with the Rules of the Arbitration
        Foundation of Southern Africa (AFSA), applicable to international
        arbitration by an arbitrator appointed by the Foundation. In the event
        that the parties do not agree to the seat, the Foundation will select
        the seat of the arbitration. If there is an inconsistency between the
        Arbitration Agreement and the Act, the Arbitration Agreement will
        prevail. These Terms shall be governed by the laws of the Republic of
        South Africa 19.2. Language of Terms. The language of these Terms shall
        be English and the parties waive any right to use and rely upon any
        other language or translations.
      </p>
      <RedTitle>20. Changes To Terms Of Use</RedTitle>
      <p>
        You can review the most current version of the Terms of Use at any time
        at this page. We reserve the right, at our sole discretion, to update,
        change, modify, replace or discontinue any part of these Terms of Use by
        posting updates and changes to our App and Website. It is your
        responsibility to check our App or Website periodically for changes and
        we may, but not liable to notify you through email, messages or
        notifications on the App or Website and effect takes place upon
        publishing of the amendments. Your continued use, or access to our App
        and Website or the Service following the posting of any changes to these
        Terms of Use constitutes acceptance of those changes.
      </p>
      <RedTitle>21. Contact Information</RedTitle>
      <p>
        Questions and concerns about this Terms of Use should be sent to us
        according to your respective region at “Nigeria” regions:
        support@repeddle.com “South Africa” regions support@repeddle.co.za
      </p>
    </Container>
  );
}
